import Helmet from "react-helmet"
import React from "react"
import SiteData from "branding/site-metadata.json"
import ThankYouPage from "@tightrope/typ"
import Data from "./data/data.json"


export default function ThankYou() {
  return(
    <section>
      <ThankYouPage siteData={SiteData} data={Data}></ThankYouPage>
    </section>
  )
}
